/*************************************************
 Module javascript pour afficher le menu en mobile
 *************************************************/

var btnMenu = $("[data-action='toggle-menu']"),
    menu = $("[data-elem='menu']");

btnMenu.click(function(){
  $(this).toggleClass("active");
  menu.toggleClass("active");
});
