/** ***************************************************************************
 * Module Javascript chargeant les helpers
 * @author tcaron <tcaron@umanit.fr>, ldaguise <ldaguise@umanit.fr>
 *****************************************************************************/

// Helpers responsive
window.BREAKPOINTS = {
    xs: 480,
    sm: 768,
    md: 992,
    lg: 1200
  };

// Contrôle si la taille d'écran est plus petite que la taille max d'un mobile
window.isMobile = function () {
  return $(document).width() < window.BREAKPOINTS.sm;
};

// Contrôle si la taille d'écran est plus grande qu'un mobile mais plus petite qu'un desktop
window.isTablet = function () {
  return window.BREAKPOINTS.sm <= $(document).width() && $(document).width() < window.BREAKPOINTS.md;
};

// Contrôle si la taille d'écran est plus grande que la taille minimum d'un desktop
window.isDesktop = function () {
  return window.BREAKPOINTS.md <= $(document).width();
};
