/*************************************************
 Module javascript changer de langue
 *************************************************/
const btn = $("[data-action='toggle-language']"),
    languageBlock = $("[data-target='toggle-language']"),
    overlay = $(".overlay"),
    header =  $(".header");

btn.click(function(e) {
    e.stopPropagation();
    e.preventDefault();
    languageBlock.toggleClass("sr-only");
    overlay.toggleClass("active");
});

languageBlock.on('click', function(e){
    e.stopPropagation();
});

$(document.body).on('click', function(){
    languageBlock.addClass("sr-only");
    overlay.removeClass("active");
});
