/*************************************************
 Module javascript pour uniformiser les hauteurs de certains blocs
 *************************************************/

var containers = $('[data-trigger="equalsize"]'),
    blocToResize = $('[data-target="toresize"]');

if(isTablet() || isDesktop()) {
  equalsize();
}

$(window).resize(function() {
  if(isTablet() || isDesktop()) {
    blocToResize.outerHeight("auto");
    equalsize();
  }
  if(isMobile()) {
    blocToResize.outerHeight("auto");
  }
});

/**
 * Pour chaque bloc d'un groupe de blocs, égalise les hauteurs
 * @returns {void}
 */
function equalsize() {
  containers.each(function () {
    var finalHeight = 0,
        container = $(this),
        blocToResize = container.find('[data-target="toresize"]');

    blocToResize.each(function () {
      if ($(this).outerHeight() > finalHeight) {
        finalHeight = $(this).outerHeight();
      }
    });
    blocToResize.outerHeight(finalHeight);
  });
}