/*
 * Welcome to your app's main JavaScript file!
 *
 * We recommend including the built version of this JavaScript file
 * (and its CSS file) in your base layout (base.html.twig).
 */

// any CSS you import will output into a single css file (app.css in this case)
import '../scss/style.scss';

import $ from 'jquery';
import 'popper.js';
import 'bootstrap';

import './tools/helpers';
import './tools/equalheight';
import './tools/smoothscroll';
import './tools/toggle-menu';
import './tools/toggle-language';

global.$ = $;
global.jQuery = $;
